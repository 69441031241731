<template>
  <v-dialog
      v-model="dialog"
      max-width="600"
      persistent
      @click:outside="closeDialog()"
  >
    <template v-slot:activator="{ on }">
      <v-btn
          v-on="on"
          small
          tile
          :elevation="0"
          style="border-radius: 0.25rem;"
          class="btn-bg-green"
      >
        <v-icon small color="white" left>mdi-plus</v-icon>
        {{ $t('tokens.titles.newContract') }}
      </v-btn>
    </template>
    <v-form v-model="valid">
      <v-card>
        <v-overlay :value="loading" absolute color="white" :opacity="0.9">
          <p class="text--disabled text-end ma-0 pa-0 code-metamask">{{ $t('tokens.titles.waitingFor') }}</p>
          <v-img :src="require('@/assets/img/metamask-logo.svg')" contain />
          <v-progress-linear class="mt-1" indeterminate color="#f5841f"/>
        </v-overlay>
        <v-card-title class="headline secondary t-bw-secondary--text px-6">
          {{ $t('tokens.titles.newContract') }}
        </v-card-title>
        <v-card-text class="pa-6">
          <v-row justify="space-between">
            <v-col cols="12">
              <v-autocomplete
                :label="$t('tokens.fields.walletAddress')"
                :rules="requireRules"
                :items="wallets"
                @change="fetchOwnProducts()"
                v-model="wallet"
                small
                outlined
                dense
                clearable
                color="primary"
                item-text="name"
                item-color="primary"
                return-object
              />
            </v-col>
            <v-col cols="8">
              <v-autocomplete
                  :label="$t('tokens.fields.selectProduct')"
                  :items="products"
                  :loading="loadingProducts"
                  v-model="product.own"
                  return-object
                  small
                  outlined
                  dense
                  clearable
                  item-color="primary"
                  item-key="id"
                  color="primary"
                  :rules="requireRules"
              >
                <template v-slot:selection="{ item }">
                  <span v-if="item">
                    {{ translate(item.language_key) | truncate(15) }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <span v-if="item">
                    {{ translate(item.language_key) }}
                  </span>
                </template>  
              </v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field
                  v-model="product.symbol"
                  :label="$t('tokens.fields.newSymbol')"
                  small
                  outlined
                  dense
                  clearable
                  color="primary"
                  :rules="requireRules"
              />
            </v-col>
          </v-row>
          <v-alert
              dense
              text
              border="left"
              type="warning"
              color="orange darken-2"
              icon="mdi-alert"
          >
            {{ $t('tokens.text.thisFunction') }} <strong>{{ $t('tokens.text.isIrrevocable') }}</strong>{{ $t('tokens.text.beCareful') }}
          </v-alert>
        </v-card-text>
        <v-card-actions class="pa-6">
          <v-spacer></v-spacer>
          <v-btn
              small
              text
              :disabled="loading"
              @click="closeDialog()"
              class="mr-2"
          >
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <ConfirmDialog :valid="!valid"
                         :loading="loading"
                         :btn-title="$t('common.buttons.confirm')"
                         :is-tooltip="false"
                         :is-btn="true"
                         :is-icon="false"
                         action="createContractToken"
          />
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import sortList from "@/services/OrderListService";
import LanguageService from "@/services/LanguajeService";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "ContractCreateComponent",

  components: { ConfirmDialog },

  data: function () {
    return {
      dialog: false,
      loading: false,
      loadingProducts: false,
      valid: false,
      product: {
        own: null,
        symbol: null
      },
      wallet: null
    }
  },

  computed: {
    ...mapGetters({
      wallets: 'web3/GET_WALLETS',
      roles: 'web3/GET_ROLES',
      products: 'web3/GET_PRODUCTS',
      requireRules: 'general/requireRules'
    })
  },

  async created() {
    await this.fetchWallets()
  },

  mounted() {
    this.$root.$on("createContractToken", async () => {
      await this.createContractToken();
    });
  },

  methods: {
    ...mapActions({
      fetchProducts: 'web3/FETCH_PRODUCTS',
      fetchWallets: 'web3/FETCH_WALLETS',
      createContract: 'web3/CREATE_CONTRACT'
    }),
    async createContractToken() {
      this.loading = true
      const payload = {
        name: this.translate(this.product.own.language_key),
        symbol: _.upperCase(this.product.symbol),
        walletAddress: this.wallet.address,
        companyId: this.wallet.company.id,
        product: this.product.own,
        roles: [{
          walletId: _.filter(this.wallets, { address: window.userAddress })[0].id,
          role: this.roles[0].role
        }, {
          walletId: this.wallet.id,
          role: this.roles[1].role
        }]
      }
      await this.createContract(payload).finally(() => {
        this.loading = false
        this.closeDialog()
      })
    },
    async fetchOwnProducts() {
      this.loadingProducts = true
      await this.fetchProducts(this.wallet.company.id).finally(() => {
        this.loadingProducts = false
      })
    },
    closeDialog() {
      if (this.loading) {
        return
      }
      this.product = {
        own: null,
        symbol: null
      }
      this.wallet = null
      this.dialog = false
    },
    translate(key, uppercase) {
     return LanguageService.getKey3(key)
    },
    orderData(list, order) {
      return sortList.orderListByUppercase(list, order)
    },
  },

  destroyed() {
    this.$root.$off('createContractToken')
  }
}
</script>

<style scoped lang="scss">
.btn-bg-green:disabled{
  background-color: rgb(228, 226, 226) !important;
  color: gray !important;;

}

.btn-bg-green{
  background-color: var(--v-primary-base) !important;
  color: white !important;;
}

.btn-bg-green:hover{
  background-color: var(--v-primary-base);
  color: white !important;
}
</style>